import Vue from 'vue';
import VueRouter from 'vue-router';
import Landing from '../views/Landing.vue';
// import Architecture from '../views/Architecture.vue';
// import Development from '../views/Development.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'landing',
    component: Landing,
  },
  // {
  //   path: '/architecture',
  //   name: 'architecture',
  //   component: Architecture,
  // },
  // {
  //   path: '/development',
  //   name: 'development',
  //   component: Development,
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
