<template>
  <div class="landing">
    <img class="avatar" src="@/assets/avatar.svg" />
    <div>
      <h2>Brice <b>Dauzats</b></h2>
      <h3>architecte et développeur de vos solutions</h3>
      <a href="https://brice.dauzats.fr/">Contactez-moi</a>
    </div>
  </div>
</template>

<script>

export default {
  components: {

  },
};
</script>

<style lang="scss" scoped>
  .landing {
    text-align: center;
  }
  .avatar {
    width: 200px;
  }

  h2 b {
    font-weight: 400;
  }
  h3 {
    font-size: 1.5em;
    margin-bottom: 2em;
  }
</style>
